require("prismjs/themes/prism-solarizedlight.css")
import React from 'react'
import './src/styles/global.css'
import { GlobalStyle } from './src/styles/global'

class TextScramble {
    constructor(el) {
        this.el = el
        this.chars = '!"£$%^&*()_+{}[]:@~/?>,<¬`\|'
        this.update = this.update.bind(this)
    }
    setText(newText) {
        const oldText = this.el.innerText
        const length = Math.max(oldText.length, newText.length)
        const promise = new Promise((resolve) => this.resolve = resolve)
        this.queue = []
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || ''
            const to = newText[i] || ''
            const start = Math.floor(Math.random() * 80)
            const end = start + Math.floor(Math.random() * 80)
            this.queue.push({ from, to, start, end })
        }
        cancelAnimationFrame(this.frameRequest)
        this.frame = 0
        this.update()
        return promise
    }
    update() {
        let output = ''
        let complete = 0
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i]
            if (this.frame >= end) {
                complete++
                output += to
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.28) {
                    char = this.randomChar()
                    this.queue[i].char = char
                }
                output += `<span class="dud">${char}</span>`
            } else {
                output += from
            }
        }
        this.el.innerHTML = output
        if (complete === this.queue.length) {
            this.resolve()
        } else {
            this.frameRequest = requestAnimationFrame(this.update)
            this.frame++
        }
    }
    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)]
    }
}

const phrases = [
    'Harry Denley'
]

function startScramble() {
    document.getElementById('SiteTitle_Original').style.display = "none";
    document.getElementById('SiteTitle_Scramble').style.display = "block";
}

function stopScramble() {
    document.getElementById('SiteTitle_Original').style.display = "block";
    document.getElementById('SiteTitle_Scramble').style.display = "none";
}

export const wrapPageElement = ({ element }) => (
    <>
        <GlobalStyle />
        {element}
    </>
)

export const onInitialClientRender = () => {
    const onKonami = (action, seq = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]) => {
        const target = seq.join(',')
        let current = []
        window.addEventListener('keydown', (e) => {
            current = current.concat(e.keyCode).slice(-seq.length)
            if (current.join(',') == target) { action(current) }
        })
    }

    onKonami(
        (seq) => {
            console.log(`Konami toggled!`)
            let isKonamiCodeActivated = (localStorage.getItem('konamiCodeActivated') ?? "0") == "0" ? false : true;
            console.log(`KonamiCode Status: ${isKonamiCodeActivated}`)
            localStorage.setItem('konamiCodeActivated', isKonamiCodeActivated ? "0" : "1");

            if (isKonamiCodeActivated) {
                return stopScramble();
            } else {
                return startScramble();
            }
        })

    let isKonamiCodeActivated = (localStorage.getItem('konamiCodeActivated') ?? "0") == "0" ? false : true;
    if (isKonamiCodeActivated) {
        startScramble();
    }

    const el = document.querySelector('#SiteTitle_Scramble > span')
    const fx = new TextScramble(el)
    let counter = 0
    const next = () => {
        fx.setText(phrases[counter]).then(() => {
            setTimeout(next, 800)
        })
        counter = (counter + 1) % phrases.length
    }
    next()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    let isKonamiCodeActivated = (localStorage.getItem('konamiCodeActivated') ?? "0") == "0" ? false : true;
    if (isKonamiCodeActivated) {
        startScramble();
    }

    const el = document.querySelector('#SiteTitle_Scramble > span')
    const fx = new TextScramble(el)
    let counter = 0
    const next = () => {
        fx.setText(phrases[counter]).then(() => {
            setTimeout(next, 800)
        })
        counter = (counter + 1) % phrases.length
    }
    next()
};